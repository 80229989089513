<template>
  <div class="row">
    <div class="col-lg-12">
      <b-card class="card-app-design min-height">
        <h1 class="text-center pt-5">Coming soon...</h1>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILESURL,
    };
  },
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}
.primary-color {
  color: #00d09c;
}
.min-height {
  min-height: 60vh;
}
</style>